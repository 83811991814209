import React, {Component} from "react";
import FooterLogo from "../assets/images/logo-elsetic-1-1.png";
import {Link as ScrollLink} from "react-scroll";

import axios from "axios";

const initialState = {
  email: '',
  sendButton:  'Suscríbete!'
}

export default class Footer extends Component {
  constructor(props) {
    super(props)
    this.state = initialState;
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  };

  onChange(e) {
    if (e.target.id === 'email') {
      this.setState({email: e.target.value});
    }
  }

  onSubmit(e) {
    this.changeButtonText('Enviando...')
    e.preventDefault();
    axios({
      method: 'post',
      url: 'https://us-central1-helzy-app.cloudfunctions.net/subscribeEmail',
      data: {
        email: this.state.email
      }
    })
        .then((response) => {
          this.changeButtonText('Enviado!')
          //setTimeout(() => this.changeButtonText(initialState.sendButton), 2000)
        })
        .catch((error) => {
          this.changeButtonText('Error!')
          setTimeout(() => this.changeButtonText(initialState.sendButton), 2000)
          console.log(error);
        });
  };

  changeButtonText(text) {
    this.setState({sendButton: text});
  }

  render() {
    const { sendButton } = this.state
    return (
        <div>
          <section className="mailchimp-one">
            <div className="container wow fadeInUp">
              <div className="inner-container">
                <div className="mailchimp-one__icon">
                  <i className="apton-icon-mail"></i>
                </div>
                <form onSubmit={this.onSubmit} className="mailchimp-one__form">
                  <input
                      type="text"
                      placeholder="¿Quieres suscribirte a nuestro boletín?"
                      disabled={true}
                  />
                  <input
                      type="text"
                      placeholder="Escribe aquí tu email"
                      id="email"
                      onChange={this.onChange}
                  />
                  <button className="thm-btn mailchimp-one__btn" type="submit">
                    <span>{sendButton}</span>
                  </button>
                </form>
              </div>
            </div>
          </section>

          <footer className="site-footer">
            <div className="site-footer__upper">
              <div className="container">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="footer-widget footer-widget__about">
                      <a href="https://www.elsetic.com">
                        <img src={FooterLogo} width="129" alt="awesome post" />
                      </a>
                    </div>
                  </div>

                  <div className="col-lg-5">
                    <div className="footer-widget__links-wrap">
                      <div className="footer-widget">
                        <h3 className="footer-widget__title">Compañia</h3>
                        <ul className="list-unstyled footer-widget__links-list">
                          <li>
                            <a href="https://www.elsetic.com/" target='_blank' rel='noreferrer'>Elsetic S.L.</a>
                          </li>
                          <li>
                            <a href="https://www.elsetic.com/our-team/" target='_blank' rel='noreferrer'>Equipo</a>
                          </li>
                          <li>
                            <a href='/#contact'>Contacto</a>
                          </li>
                        </ul>
                      </div>
                      <div className="footer-widget">
                        <h3 className="footer-widget__title">Enlaces</h3>
                        <ul className="list-unstyled footer-widget__links-list">
                          <li>
                            <a href="/documentation" target='_blank' rel='noreferrer'>Documentación</a>
                          </li>
                          <li>
                            <a href="/privacy-policy" target='_blank' rel='noreferrer'>Política de Privacidad</a>
                          </li>
                          <li>
                            <a href="/terms-of-use" target='_blank' rel='noreferrer'>Términos de Uso</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 d-flex align-items-center justify-content-xl-end justify-content-lg-end justify-content-md-center justify-content-sm-center">
                    <div className="footer-widget">
                      <div className="footer-widget__social">
                        <a href="https://www.linkedin.com/company/68765966" target='_blank' rel='noreferrer'>
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                        <a href="https://twitter.com/elsetic_sl" target='_blank' rel='noreferrer'>
                          <i className="fab fa-twitter"></i>
                        </a>
                        <a href="https://www.instagram.com/elsetic_sl/" target='_blank' rel='noreferrer'>
                          <i className="fab fa-instagram"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="site-footer__bottom">
              <div className="container text-center">
                <p>© Copyright 2022 by <a href='https://www.elsetic.com' target='_blank' rel='noreferrer'>Elsetic S.L.</a></p>
              </div>
            </div>
          </footer>
        </div>
    );
  }
}
